<template>
  <div>
    <div v-if="checkinIsApproved" class="moment-statuses">
      <div v-for="status in statuses"
        class="moment-statuses__item"
        :class="`moment-statuses__item--${status.status}`"
        :id="status.key"
        :key="status.key">
        <i :class="status.icon" />
        <i v-if="status.warning" class="fa-regular fa-warning text-warning" />
        <a v-if="status.link" :href="status.link" class="d-flex align-items-center gap-2">
          <i class="fa-regular fa-link" />
          <span>Link to post</span>
        </a>
        <BTooltip v-if="status.text" :target="status.key" :title="status.text" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  FB_STATUSES
} from './helpers'

export default {
  props: {
    checkin: {
      type: Object,
      required: true
    }
  },
  methods: {
    webhookStatus(status, deliveredAt) {
      if (status === 'skipped') return 'skipped'
      if (deliveredAt) return 'delivered'

      return 'pending'
    },
    facebookStatus(status) {
      return FB_STATUSES[status]
    },
    instagramPostPublished(checkin) {
      return checkin.instagramPostingStatus === 'published' && checkin.instagramPostUrl
    },
    facebookPostPresent(checkin) {
      return checkin.facebookPostingStatus !== 'skipped'
    },
    instagramPostPresent(checkin) {
      return checkin.instagramPostingStatus !== 'skipped'
    }
  },
  computed: {
    checkinIsApproved() {
      return this.checkin.aasmState === 'approved'
    },
    gbpPostStatus() {
      const { checkin } = this
      if (checkin.localPostStatus) return checkin.localPostStatus
      if (checkin.business.disconnected) return 'disconnected'
      if (checkin.postingError) return 'failed'

      return 'pending'
    },
    facebookPostPublished() {
      const { checkin } = this
      return checkin.facebookPostingStatus === 'published' && checkin.facebookPostUrl
    },
    statuses() {
      const { checkin } = this
      const checkinId = checkin.obfuscatedId
      const statusKey = (keyName) => `${checkinId}-${keyName}`

      const webhookStatus = this.webhookStatus(checkin.zapierWebhookStatus, checkin.zapierWebhookDeliveredAt)

      const formattedStatuses = [
        {
          key: statusKey('gbp'),
          status: this.gbpPostStatus,
          icon: 'fa-brands fa-google',
          url: this.checkin.localPostSearchUrl,
          text: this.gbpPostStatus === 'disconnected' ? 'skipped' : this.gbpPostStatus
        },
        {
          key: statusKey('webhook'),
          status: webhookStatus,
          icon: 'fa-solid fa-webhook',
          text: webhookStatus
        }
      ]

      if (checkin.fbEnabled && checkin.facebookPostingStatus) {
        formattedStatuses.push({
          key: statusKey('facebook'),
          status: checkin.facebookPostingStatus,
          icon: 'fa-brands fa-facebook',
          url: checkin.facebookPostUrl,
          text: checkin.facebookPostingError
        })
      }

      if (checkin.igEnabled && checkin.instagramPostingStatusigEnabled && checkin.igPostingStatus) {
        let tooltipText = checkin.instagramPostingError

        if (checkin.instagramPartlyPublished) {
          tooltipText = 'Failed to post some of the media'
        }

        formattedStatuses.push({
          key: statusKey('instagram'),
          status: checkin.instagramPostingStatus,
          icon: 'fa-brands fa-instagram',
          url: checkin.instagramPostUrl,
          text: tooltipText,
          warning: checkin.instagramPartlyPublished
        })
      }

      return formattedStatuses
    }
  }
}
</script>
